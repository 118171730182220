import React from 'react';

import { WpImageType } from 'components/atoms/wp-image';

import {
  StyledHeading,
  StyledImage,
  StyledImageWrapper,
  StyledWrapper,
  StyledYTButton,
} from './yt-movie-section.styles';

export type YtMovieSectionType = {
  placeholderText: string;
  naglowek: string;
  youtubeLink: string;
  placeholderImage: WpImageType;
};

const YtMovieSection = ({
  placeholderText,
  naglowek,
  youtubeLink,
  placeholderImage,
}: YtMovieSectionType) => (
  <StyledWrapper>
    <StyledImageWrapper>
      <StyledHeading html={naglowek} color="gray100" component="h2" variant="title2" />
      <StyledImage {...placeholderImage} />
      <StyledYTButton aria-label={youtubeLink} label={placeholderText} />
    </StyledImageWrapper>
  </StyledWrapper>
);

export default YtMovieSection;
