import React from 'react';

import DownloadArrow from 'components/atoms/icon/icons/download-arrow';

import {
  StyledColumn,
  StyledContent,
  StyledDocument,
  StyledDocumentDescription,
  StyledDocuments,
  StyledDocumentTitle,
  StyledDownlaodArrowContainer,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
} from './company-pdf-section.styles';

export type CompanyPdfSectionType = {
  downloadLabel: string;
  file: { sourceUrl: string; filename: string };
};

export type CompanyPdfSectionsType = {
  isWhite?: boolean;
  heading: string;
  subHeading: string;
  documentsList: CompanyPdfSectionType[];
};

const CompanyPdfSections = ({
  heading,
  subHeading,
  documentsList,
  isWhite = false,
}: CompanyPdfSectionsType) => (
  <StyledWrapper isWhite={isWhite}>
    <StyledContent>
      <StyledSubHeading variant="title6" color="gray40">
        {subHeading}
      </StyledSubHeading>
      <StyledColumn>
        {heading ? <StyledTitle html={heading} variant="title2" component="h2" /> : null}

        {documentsList ? (
          <StyledDocuments>
            {documentsList.map((document, idx) => (
              <StyledDocument key={document.downloadLabel} idx={idx}>
                <StyledDocumentTitle variant="title3" component="h3">
                  {document.file ? document.file.filename : ''}
                </StyledDocumentTitle>
                <a href={document.file ? document.file.sourceUrl : ''} style={{ display: 'flex' }}>
                  <StyledDocumentDescription variant="title4" color="primary50">
                    {document.downloadLabel}
                  </StyledDocumentDescription>
                  <StyledDownlaodArrowContainer>
                    <DownloadArrow style={{ fill: 'red' }} />
                  </StyledDownlaodArrowContainer>
                </a>
              </StyledDocument>
            ))}
          </StyledDocuments>
        ) : null}
      </StyledColumn>
    </StyledContent>
  </StyledWrapper>
);
export default CompanyPdfSections;
