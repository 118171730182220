import React from 'react';

const DownloadArrow = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 14.5C19.5128 14.5 19.9355 14.886 19.9933 15.3834L20 15.5V17.5C20 19.0977 18.7511 20.4037 17.1763 20.4949L17 20.5H3C1.40232 20.5 0.0963391 19.2511 0.00509269 17.6763L0 17.5V15.5C0 14.9477 0.447715 14.5 1 14.5C1.51284 14.5 1.93551 14.886 1.99327 15.3834L2 15.5V17.5C2 18.0128 2.38604 18.4355 2.88338 18.4933L3 18.5H17C17.5128 18.5 17.9355 18.114 17.9933 17.6166L18 17.5V15.5C18 14.9477 18.4477 14.5 19 14.5ZM10 0.5C10.5523 0.5 11 0.947715 11 1.5V11.085L12.2929 9.79289C12.6534 9.43241 13.2206 9.40468 13.6129 9.7097L13.7071 9.79289C14.0676 10.1534 14.0953 10.7206 13.7903 11.1129L13.7071 11.2071L10.7071 14.2071L10.6631 14.2485L10.5953 14.3037L10.4841 14.3753L10.3713 14.4288L10.266 14.4642L10.1175 14.4932L10 14.5L9.92476 14.4972L9.79927 14.4798L9.68786 14.4503L9.57678 14.4063L9.47929 14.354L9.38325 14.2872C9.35153 14.2623 9.32136 14.2356 9.29289 14.2071L6.29289 11.2071C5.90237 10.8166 5.90237 10.1834 6.29289 9.79289C6.65338 9.43241 7.22061 9.40468 7.6129 9.7097L7.70711 9.79289L9 11.085V1.5C9 0.947715 9.44771 0.5 10 0.5Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadArrow;
