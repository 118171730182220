import React from 'react';
import { DefaultType } from 'types/default';

import { BannerCardType } from 'components/molecules/banner-card';
import { TypographyVariantType } from 'components/atoms/typography';

import {
  StyldSubTitle,
  StyledCardSection,
  StyledContent,
  StyledDescription,
  StyledTextSection,
  StyledTitle,
  StyledWrapper,
} from './text-banner-card-software.styles';

export type optionalBannerCardType = {
  isVisible?: boolean;
  subTitle?: string;
  text?: string;
  extraTitle?: string;
  extraText?: string;
  textColumn?: boolean;
  tiles?: boolean;
  variantHeading?: TypographyVariantType;
};

export type TextBannerCardType = {
  // eslint-disable-next-line react/no-unused-prop-types
  cardList: BannerCardType[]; // use on page files
} & Record<'heading', string>;

export type propsTextBannerCardType = {
  children?: React.ReactNode;
} & TextBannerCardType &
  DefaultType &
  optionalBannerCardType;

const TextBannerCardSoftware = ({
  isVisible = false,
  children,
  subTitle,
  heading,
  text,
  extraTitle,
  extraText,
  tiles,
  textColumn,
  maxWidth,
  margin,
  padding,
  bgColor,
  variantHeading,
}: propsTextBannerCardType) => {
  const extraTextBlock = (
    <>
      {extraTitle && <StyledTitle html={extraTitle} variant="title2" component="h2" sub />}
      {extraText && <StyledDescription html={extraText} variant="body1" />}
    </>
  );

  return (
    <StyledWrapper margin={margin} padding={padding} bgColor={bgColor}>
      <StyledContent maxWidth={maxWidth} isVisible={isVisible}>
        {subTitle && <StyldSubTitle html={subTitle} variant="title4" />}
        <StyledTextSection textColumn={textColumn}>
          <StyledTitle
            html={heading}
            variant={variantHeading || 'title1'}
            textColumn={textColumn}
          />
          <StyledDescription variant="body1" html={text} textColumn={textColumn} />
          {extraTextBlock}
        </StyledTextSection>
        <StyledCardSection tiles={tiles}>{children}</StyledCardSection>
      </StyledContent>
    </StyledWrapper>
  );
};

export default TextBannerCardSoftware;
