import React, { ReactElement } from 'react';

import { LinkUrlType } from 'components/atoms/link';
import Typography from 'components/atoms/typography';
import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  PositionContainerTitle,
  StyledBigWpImage,
  StyledBulletList,
  StyledDotsOrange,
  StyledRecruitButton,
  StyledRecruitTeamList,
  StyledSmallWpImage,
  StyledTitle,
  StyledWrapper,
  TextContainer,
} from './image-bullet-section.styles';

export type ImageBulletSectionType = {
  children?: ReactElement;
  bulletList?: any;
  showDots: boolean;
  image: WpImageType;
  linkButton?: LinkUrlType;
  linkTitleName?: string;
  subtitleTop?: string;
} & Record<'titleTop' | 'title', string>;

const ImageBulletSection = ({
  bulletList,
  titleTop,
  subtitleTop,
  showDots,
  image,
  title,
  linkTitleName,
  linkButton,
}: ImageBulletSectionType) => {
  const { isMobile } = useScreenService();

  return (
    <StyledWrapper>
      <PositionContainerTitle>
        <StyledSmallWpImage {...image} />
        <TextContainer>
          {subtitleTop ? (
            <Typography color="gray40" variant="title3" html={subtitleTop} component="h3" />
          ) : null}
          <StyledTitle color="gray100" variant="title1" component="h1" html={titleTop} />
          <StyledRecruitTeamList color="gray100" variant="body1" html={title} />
          <StyledBulletList>
            <ul>
              {bulletList
                ? bulletList.map(({ text, idx }: any) => <li key={idx}>{text}</li>)
                : null}
            </ul>
          </StyledBulletList>
          {linkTitleName ? <StyledRecruitButton label={linkTitleName} link={linkButton} /> : null}
        </TextContainer>
        {showDots ? (
          <StyledDotsOrange
            width={isMobile ? 7 : 6}
            height={isMobile ? 6 : 7}
            color={isMobile ? 'complementary2' : 'complementary2'}
          />
        ) : null}
        <StyledBigWpImage {...image} />
      </PositionContainerTitle>
    </StyledWrapper>
  );
};

export default ImageBulletSection;
