import styled from 'styled-components';
import { breakpoint } from 'theme';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

export const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray0};
  padding-top: ${({ theme }) => theme.gap.mdl};
  margin-inline: auto;
  padding-bottom: 5px;
  ${breakpoint.md} {
    padding-top: ${({ theme }) => theme.gap.xl};
  }
`;
export const StyledImageWrapper = styled.div`
  max-width: 328px;
  margin-inline: auto;
  margin-bottom: 114px;
  ${breakpoint.md} {
    max-width: ${({ theme }) => theme.wrapper};
    margin-inline: auto;
  }
`;

export const StyledHeading = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.gap.sm};
  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledImage = styled(WpImage)`
  height: 196px;
  width: 328px;
  ${breakpoint.md} {
    height: 400px;
    width: 100%;
  }
`;

export const StyledYTButton = styled(Button)`
  margin-top: -110px;
  margin-left: 35%;
  ${breakpoint.md} {
    margin-top: -200px;
    margin-left: 45%;
  }
`;
