import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<{ isWhite?: boolean }>`
  background-color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.white : theme.colors.grayscaleGray0};
  padding-inline: ${({ theme }) => theme.gap.sm};
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.gap.xs} ${breakpoint.md} {
    padding-bottom: 40px;
  }
`;

export const StyledDownlaodArrowContainer = styled.div`
  margin-left: 14px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.primary50};

  ${breakpoint.md} {
    margin-left: 14px;
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.gray100};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.gap.md};
  padding-bottom: ${({ theme }) => theme.gap.xs};
  ${breakpoint.md} {
    padding-top: ${({ theme }) => theme.gap.xl};
    flex-direction: row;
    gap: 30px;
    padding-bottom: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledColumn = styled.div`
  min-width: ${({ theme }) => theme.wrapperXxs};
  display: flex;
  flex-direction: column;

  ${breakpoint.md} {
    min-width: 733px;
  }
`;

export const StyledTitle = styled(Typography)`
  strong {
    text-decoration-color: transparent;

    ${breakpoint.md} {
      text-decoration-color: ${({ theme }) => theme.colors.primary50};
    }
  }
`;

export const StyledSubHeading = styled(Typography)`
  min-width: 347px;
  padding-bottom: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.xs};
  }
`;

export const StyledDocuments = styled.ol`
  margin-left: 40px;
  list-style-type: none;
  display: grid;
  gap: 4px;
  padding-block: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    max-width: 680px;
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => `${theme.gap.sm}  60px`};
    padding-block: 0;
  }
`;

export const StyledDocument = styled.li<{ idx: number }>`
  --iconurl: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 0C10.2652 0 10.5196 0.105357 10.7071 0.292893L15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V17C16 18.6569 14.6569 20 13 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H10ZM7.999 2H3C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H13C13.5523 18 14 17.5523 14 17V8H9C8.48716 8 8.06449 7.61396 8.00673 7.11662L8 7L7.999 2ZM13.586 6L9.999 2.414L10 6H13.586Z' fill='black'/%3E%3C/svg%3E%0A");
  ${({ idx, theme }) =>
    idx === 4 || idx === 5 ? '' : `border-bottom:1px solid ${theme.colors.gray40}`};
  border-top: none;
  position: relative;
  margin-top: -28px;
  padding-left: 20px;
  margin-left: -16px;
  &:before {
    content: var(--iconurl);
    height: 10px;
    width: 10px;
    position: relative;
    left: -40px;
    top: 42px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: ${({ theme }) => theme.gap.sm};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray40};
  }
  ${breakpoint.md} {
    border-bottom: none;
    ${({ idx, theme }) =>
      idx === 0 || idx === 1 ? '' : `border-top:1px solid ${theme.colors.gray40}`};
    min-height: 157px;
    padding-left: 22px;
    margin-top: 0px;
    &:before {
      top: 28px;
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const StyledDocumentTitle = styled(Typography)`
  padding-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-top: 0;
  }
`;

export const StyledDocumentDescription = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.sm};
  text-decoration: underline;

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.sm};
    text-decoration: none;
  }
`;
