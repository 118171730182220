import styled from 'styled-components';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';
import button from 'components/atoms/button';
import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

export const StyledWrapper = styled.div`
  position: relative;
  padding-bottom: 0;

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.xl};
  }
`;

export const PositionContainerTitle = styled(Container)`
  max-width: ${({ theme }) => theme.wrapperXxl};
  max-height: 768px;

  ${breakpoint.md} {
    display: flex;
    background-color: white;
  }
`;

export const TextContainer = styled(Container)<{ isWhite?: boolean }>`
  max-width: 360px;
  padding-block: ${({ theme }) => theme.gap.md};
  padding-inline-start: 0px;
  padding-inline-end: 0px;

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
    max-width: 734px;
    margin-top: ${({ isWhite }) => (isWhite ? '0' : '70px')};
    padding-inline-start: ${({ theme }) => theme.gap.xxl};
    padding-inline-end: 113px;
    margin-bottom: -20px;
  }
`;

export const StyledDotsOrange = styled(DotsGrid)`
  z-index: 3;
  display: none;

  ${breakpoint.md} {
    display: none;
  }

  ${breakpoint.xl} {
    display: block;
    right: ${({ theme }) => theme.gap.md};
    top: 45%;
  }

  ${breakpoint.xxl} {
    right: 80%;
    display: block;
  }
`;

export const StyledTitle = styled(Typography)`
  word-break: keep-all;
  margin-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    margin-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledRecruitTeamList = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.sm};
  ul {
    list-style-type: square;
    list-style-position: inside;
    padding-block: ${({ theme }) => theme.gap.sm};
  }
  li {
    padding-block: ${({ theme }) => theme.gap.xxs};
  }
  li::marker {
    color: ${({ theme }) => theme.colors.primary50};
  }
  ${breakpoint.lg} {
    margin-bottom: ${({ theme }) => theme.gap.md};
    ul {
      padding-block: ${({ theme }) => theme.gap.md};
    }
  }
`;

export const StyledBulletList = styled.div`
  ul {
    list-style-type: square;
    list-style-position: inside;
  }
  li {
    padding-block: ${({ theme }) => theme.gap.xxs};
  }
  li::marker {
    color: ${({ theme }) => theme.colors.primary50};
  }
  ${breakpoint.lg} {
    margin-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledSmallWpImage = styled(WpImage)`
  z-index: 2;
  min-width: 360px;
  height: 100%;
  height: 216px;
  left: -16px;
  display: block;
  img {
    object-position: 0px 0px;
  }

  ${breakpoint.md} {
    display: none;
  }
`;

export const StyledBigWpImage = styled(WpImage)`
  z-index: 2;
  display: none;
  img {
    object-position: 0px 0px;
  }

  ${breakpoint.md} {
    display: block;
    height: 706px;
    min-width: 768px;
    left: auto;
  }
`;

export const StyledRecruitButton = styled(button)`
  max-width: 360px;
  ${breakpoint.md} {
    max-width: 720px;
    margin-top: -32px;
  }
`;
